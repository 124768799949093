<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="officialNoteForm">
      <app-textarea-input v-model="note" name="Not" placeholder="Not giriniz..." label="Not:" rules="required" rows="5" />
      <app-button text="Oluştur" block @click="createNote" />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      note: null,
    };
  },
  computed: {
    official() {
      return this.$store.getters.appModalItem?._id;
    },
  },
  methods: {
    createNote() {
      this.$validate(this.$refs.officialNoteForm, () => {
        this.$axios
          .post(
            "/notes",
            {
              note: this.note,
              refType: "customer",
              related: this.official,
            },
            { loading: "sidebar" }
          )
          .then((result) => {
            this.$store.commit("newOfficialNote", result.data);
            this.$store.commit("cleanAppModalSidebar");
            this.$emitter.$emit("Notification", {
              title: "Not Oluşturuldu",
              variant: "success",
            });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              title: "Hata Oluştu",
              variant: "danger",
              message: err?.data?.message ?? null,
            });
          });
      });
    },
  },
};
</script>

<style></style>
