<template>
  <b-custom-tabs v-show="!isVisitCollapseVisible" id="physicianVisitTabs" :tab-index="tabIndex" :tabs="tabs" lazy-once />
</template>

<script>
import CompletedVisits from "./CompletedVisits.vue";
import NotCompletedVisits from "./NotCompletedVisits.vue";
import PlanedVisits from "./PlanedVisits.vue";
import VisitDetailModal from "./details/VisitDetailModal.vue";

export default {
  components: { VisitDetailModal },
  data() {
    return {
      tabs: [
        { title: "Planlananlar", component: PlanedVisits, route: "physician-visits-planed" },
        { title: "Yapılanlar", component: CompletedVisits, route: "physician-visits-completed" },
        { title: "Yapılmayanlar", component: NotCompletedVisits, route: "physician-visits-not-completed" },
      ],
    };
  },
  computed: {
    tabIndex: {
      get() {
        return this.$route?.matched[0]?.props?.default?.tabIndex;
      },
      set() {},
    },
    isVisitCollapseVisible() {
      return this.$store.getters.isVisitCollapseVisible;
    },
  },
};
</script>

<style></style>
