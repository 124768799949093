<template>
  <app-overlay loading-variable="modalOverlay">
    <searchable-table :fields="fields" class="border mb-1" :items="supportedEmployees">
      <template #modalbutton>
        <app-button class="flex-1-md" text="Çalışan Seç" icon="PlusIcon" size="md" @click="showForm" />
      </template>
      <template #actions="{ item }">
        <app-icon-button variant="gradient-danger" icon="TrashIcon" @click="deleteSupportEmployee(item)" />
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import SupportedEmployeeForm from "./SupportedEmployeeForm.vue";

export default {
  props: {
    employeeType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    fields() {
      return this.breakPoint === "xs"
        ? [
            { key: "employee.fullname", label: "Ad Soyad", sortable: true },
            { key: "actions", label: "İşlemler", sortable: false },
          ]
        : [
            { key: "employee.fullname", label: "Ad Soyad", sortable: true },
            ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil", sortable: true }]),
            { key: "employee.email", label: "E-posta", sortable: true },
            { key: "employee.phone", label: "Telefon", sortable: true },
            {
              key: "createdAt",
              label: "Eklenme Tarihi",
              sortable: true,
              formatDateTime: true,
            },
            { key: "actions", label: "İşlemler", sortable: false },
          ];
    },
    supportedEmployees() {
      return this.$store.getters.supportTeams(this.employeeType);
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    breakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
  mounted() {
    this.getSupportTeams();
  },
  methods: {
    showForm() {
      this.$showAppModalSidebar("Çalışan Seç", SupportedEmployeeForm, { employeeType: this.employeeType });
    },
    getSupportTeams() {
      if (this.selectedCompany) {
        this.$axios
          .get("/employees/support-team/list", { params: { company: this.selectedCompany }, loading: "modal" })
          .then((res) => {
            this.$store.commit("setSupportTeams", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteSupportEmployee(item) {
      this.$confirm({ message: "Çalışanı silmek istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/employees/support-team/${item?._id}`, { loading: "table" })
          .then(() => {
            this.$emitter.$emit("Notification", {
              title: "Çalışan Silindi.",
              variant: "success",
            });
            this.$store.commit("deleteSupportTeamEmployee", item?._id);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style></style>
