<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="employeeform">
      <branch-select v-model="data.branch" :company="selectedCompany" :reduce="(option) => option._id" required />
      <app-input v-model="data.fullname" name="Ad Soyad" label="Ad Soyad:" placeholder="Ad Soyad..." rules="required" />
      <app-mask-input v-model="data.tcNo" name="TC Kimlik No" input-mask="###########" label="TC Kimlik No:" placeholder="TC Kimlik No..." rules="required|min:11" />
      <app-input v-model="data.email" type="email" name="E-Posta" label="E-Posta:" placeholder="E-Posta..." rules="email" />
      <app-input v-model="data.phone" type="phone" name="Telefon" label="Telefon:" placeholder="Telefon..." />
      <app-input v-model="data.duty" name="Görevi" label="Görevi:" placeholder="Görevi..." rules="required" />
      <app-date-input v-model="data.dateOfStartWork" name="İşe Giriş Tarihi" label="İşe Giriş Tarihi:" placeholder="Tarih Seçiniz..." />
      <app-date-input v-model="data.dateOfEximination" name="İşe Giriş Muayene Tarihi" label="İşe Giriş Muayene Tarihi:" placeholder="Tarih Seçiniz..." />
      <app-date-input v-model="data.dateOfWorkSafetyTraining" name="İSG Eğitim Tarihi" label="İSG Eğitim Tarihi:" placeholder="Tarih Seçiniz..." />
      <hr />
      <app-button text="Oluştur" block @click="newEmployee" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    query() {
      return this.$store.getters.appSidebarItem;
    },
  },
  mounted() {
    this.data.branch = this.selectedBranch;
  },
  methods: {
    setData() {
      return {
        ...this.data,
        company: this.selectedCompany,
        phone: this.data.phone === "0" || !this?.data?.phone ? null : this.data.phone?.replace(/\D/g, ""),
      };
    },
    newEmployee() {
      this.$validate(this.$refs.employeeform, () => {
        this.$axios
          .post("/employees", this.setData(), { loading: "sidebar" })
          .then(() => {
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: "Çalışan başarıyla sisteme eklendi." });
            this.$store.dispatch("getEmployees", {
              query: this.query,
            });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
          });
      });
    },
  },
};
</script>
