<template>
  <app-overlay>
    <searchable-table class="border" :items="branches" :fields="fields" hover @row-clicked="selectBranch">
      <template #modalbutton>
        <h3 class="m-0 mr-50">Sicil Seçiniz</h3>
        <app-button v-if="selectedBranch" @click="cleanSelectedBranch" text="Seçimi Kaldır" icon="XIcon" />
      </template>
      <template #name="{ item }">
        <span v-b-tooltip.hover.top="item.name.length > 20 ? item.name : ''">
          {{ $fn(item.name, 20) }}
        </span>
      </template>
      <template slot="companyConfirm" slot-scope="data">
        <b-badge :variant="$variants[data.item.companyConfirm]">
          {{ $capitalize(data.item.companyConfirm) }}
        </b-badge>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
export default {
  computed: {
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    physician() {
      return this.$store.getters?.user?.physician._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    branches() {
      return this.$store.getters.assignedBranches;
    },
    fields() {
      if (this.windowWidth < 768) {
        return [
          { key: "name", label: "Sicil", sortable: true },
          { key: "recordNo", label: "Sicil No", sortable: true },
        ];
      }
      return [
        { key: "name", label: "Sicil", sortable: true },
        { key: "recordNo", label: "Sicil No", sortable: true },
        { key: "companyConfirm", label: "Firma Onayı", sortable: true },
      ];
    },
  },
  methods: {
    getAssignedBranches() {
      this.selectedCompany && this.physician && this.$store.dispatch("getAssignedBranches", { physician: this.physician, company: this.selectedCompany?._id });
    },
    selectBranch(item) {
      this.$store.commit("setSelectedBranch", item._id);
      this.$store.commit("setBranchCollapseVisible", false);
    },
    cleanSelectedBranch() {
      this.$store.commit("setSelectedBranch", null);
      this.$store.commit("setBranchCollapseVisible", false);
    },
  },
  watch: {
    selectedCompany() {
      this.getAssignedBranches();
    },
  },
  created() {
    this.getAssignedBranches();
  },
};
</script>

<style></style>
