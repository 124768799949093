<template>
  <b-custom-tabs
    id="CompanyOfficialTabs"
    :tabs="tabs"
    lazy-once
  />
</template>

<script>
import OfficialInfo from './OfficialInfo.vue'
import OfficialNotesList from './notes/OfficialNotesList.vue'

export default {
  data() {
    return {
      tabs: [
        { title: 'Genel Bilgiler', component: OfficialInfo },
        { title: 'Notlar', component: OfficialNotesList },
      ],
    }
  },
}
</script>

<style></style>
