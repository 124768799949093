<template>
  <b-card v-if="selectedCompany" :body-class="windowWidth > 767 ? 'p-1' : 'p-0 py-1'">
    <b-custom-tabs id="physicianTabs" :tab-index="tabIndex" :tabs="tabs" lazy />
  </b-card>
</template>

<script>
import EmployeeList from "./employees/EmployeeList.vue";
import PlansList from "./plans/PlansList.vue";
import detectionAndSuggestionList from "./actions/detectionAndSuggestion/detectionAndSuggestionList.vue";
import ObservationList from "./actions/observations/ObservationList.vue";
import incidentList from "./actions/incidents/incidentList.vue";
import TrainingList from "./trainings/TrainingList.vue";
import DocumentList from "./documents/DocumentList.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Çalışanlar", component: EmployeeList, route: "physician-isg-employees" },
        { title: "Tespit / Öneri Defteri", component: detectionAndSuggestionList, route: "physician-isg-detection-suggestions" },
        { title: "Gözlemler", component: ObservationList, route: "physician-isg-observations" },
        { title: "Olaylar", component: incidentList, route: "physician-isg-incidents" },
        { title: "Eğitimler", component: TrainingList, route: "physician-isg-trainings" },
        { title: "Toplantılar" },
        { title: "Planlar", component: PlansList, route: "physician-isg-plans" },
        { title: "Dokümanlar", component: DocumentList, route: "physician-isg-documents" },
        { title: "Kontrol Listeleri" },
        { title: "Ekipmanlar", route: "physician-isg-equipments" },
        { title: "Atamalar", component: () => import("./assignments/AssignmentList.vue"), route: "physician-isg-assignments" },
      ],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    tabIndex: {
      get() {
        return this.$route?.matched[0]?.props?.default?.tabIndex;
      },
      set() {},
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
};
</script>

<style></style>
