<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @changed="getOfficials"
      class="border mb-50"
      :items="items"
      :fields="fields"
      :total-rows="total"
      hover
      @row-clicked="showOfficialModal"
      searchable
    >
      <template #modalbutton>
        <app-button class="flex-1-md" text="Yetkili Ekle" icon="PlusIcon" size="md" @click="$showAppSidebar('Yetkili Ekle', OfficialForm, { callback: getOfficials })" />
      </template>
      <template #actions="{ item }">
        <app-icon-button
          @click="deleteCustomer(item)"
          v-if="item.auth.role.key === 'smsOfficial'"
          v-b-tooltip.hover.bottom="'Kaldır'"
          variant="gradient-primary"
          icon="TrashIcon"
        />
        <span v-else> - </span>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import OfficialForm from "./OfficialForm.vue";
import OfficialModal from "./details/OfficialModal.vue";

export default {
  data() {
    return {
      OfficialForm,
      filters: {},
    };
  },
  computed: {
    fields() {
      return this.breakPoint === "xs"
        ? [
            { key: "auth.fullname", label: "İsim", sortable: true },
            { key: "actions", label: "İşlemler" },
          ]
        : [
            { key: "auth.fullname", label: "İsim", sortable: true },
            { key: "auth.phone", label: "Telefon", sortable: true },
            { key: "auth.role.value", label: "Rol", sortable: true },
            { key: "duty", label: "Unvan", sortable: true },
            { key: "actions", label: "İşlemler" },
          ];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    breakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    items() {
      return this.$store.getters.officials?.data ?? [];
    },
    total() {
      return this.$store.getters.officials?.total ?? 0;
    },
  },
  methods: {
    getOfficials() {
      this.$axios
        .get("/customer/list/paginated", { params: { ...this.filters, company: this.selectedCompany, isActive: true }, loading: "table" })
        .then((res) => {
          this.$store.commit("setOfficials", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showOfficialModal(item) {
      this.$showAppModal({
        component: OfficialModal,
        size: "lg",
        title: item?.fullname,
        centered: false,
        item,
      });
    },
    deleteCustomer(item) {
      this.$confirm({ message: "Yetkiliyi silmek istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/customer/delete-sms-official/${item?._id}`)
          .then(() => {
            this.getOfficials();
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı" });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  watch: {
    selectedCompany() {
      this.getOfficials();
    },
  },
};
</script>

<style></style>
