<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="officialForm">
      <app-input v-model="auth.fullname" name="Ad Soyad" label="Ad Soyad:" placeholder="Ad Soyad..." rules="required" />
      <app-input v-model="auth.email" name="E-Posta" type="email" label="E-Posta:" placeholder="E-Posta..." rules="required|email" />
      <app-input v-model="auth.phone" name="Telefon" type="phone" label="Telefon:" placeholder="Telefon..." rules="required|min:13" />
      <app-input v-model="customer.duty" name="Unvan" label="Unvan:" placeholder="Unvan..." rules="required" />
      <hr />
      <app-button text="Oluştur" block @click="create" />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      auth: {},
      customer: {},
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    callback() {
      return this.$store.getters.appSidebarItem?.callback;
    },
  },
  methods: {
    setData() {
      return {
        auth: {
          ...this.auth,
          phone: this.auth.phone.replace(/\D/g, ""),
        },
        customer: {
          ...this.customer,
          company: this.selectedCompany,
        },
      };
    },
    create() {
      this.$validate(this.$refs.officialForm, () => {
        this.$axios
          .post("/customer/create-sms-user", this.setData(), { loading: "sidebar" })
          .then((res) => {
            this.callback && this.callback();
            /* this.$store.commit("newOfficial", res.data); */
            this.$store.commit("cleanAppSidebar");
            this.$emitter.$emit("Notification", {
              title: "Yetkili Eklendi.",
              variant: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", {
              title: "Hata Oluştu.",
              variant: "danger",
              message: err?.data?.message ?? null,
            });
          });
      });
    },
  },
};
</script>

<style></style>
