<template>
  <app-overlay>
    <b-card :body-class="{ 'p-0': windowWidth < 767, 'p-1': windowWidth > 768 }" class="m-0" :class="{ border: windowWidth > 768 }">
      <b-row>
        <b-col lg="6">
          <app-detail-table :fields="firstField" :data="companyData">
            <template #name="{ name }">
              {{ $capitalize(name) }}
            </template>
            <template #contractDate="data">
              {{ $FormattedDate(data.contractDate) }}
            </template>
          </app-detail-table>
        </b-col>
        <b-col lg="6">
          <app-detail-table :fields="secondField" :data="companyData">
            <template #contractDate="data">
              {{ $FormattedDate(data.contractDate) }}
            </template>
          </app-detail-table>
        </b-col>
      </b-row>
    </b-card>
  </app-overlay>
</template>

<script>
export default {
  data: () => ({
    firstField: [
      { key: "name", label: "Firma Unvanı", icon: "BriefcaseIcon" },
      { key: "naceCode", label: "Nace Kodu", icon: "FileIcon" },
      {
        key: "sector.value",
        label: "Sektör",
        icon: "BriefcaseIcon",
        capitalize: true,
      },
    ],
    secondField: [
      { key: "contractNumber", label: "Sözleşme No", icon: "FileTextIcon" },
      { key: "contractDate", label: "Sözleşme Tarihi", icon: "CalendarIcon" },
      { key: "taxNo", label: "Vergi Numarası", icon: "FileIcon" },
      { key: "employeeCount", label: "Toplam Çalışanlar", icon: "UsersIcon" },
    ],
    companyData: {},
  }),
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  watch: {
    selectedCompany() {
      this.getCompanyData();
    },
  },
  mounted() {
    this.getCompanyData();
  },
  methods: {
    getCompanyData() {
      if (this.selectedCompany) {
        this.$axios
          .get(`/company/${this.selectedCompany?._id}`, { params: { populates: JSON.stringify(["sector", "employeeCount"]) }, loading: "table" })
          .then((result) => {
            this.companyData = result.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style></style>
