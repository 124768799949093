<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="visitform">
      <assigned-branches v-if="selectedCompany" v-model="branch" />
      <div v-else>
        <app-select-input
          name="Firma"
          v-model="companySelect"
          :options="companies"
          label="Firma:"
          placeholder="Firma Seçiniz..."
          select_label="company.name"
          rules="required"
          :loading="$store.getters.selectBoxOverlay"
        />
        <app-select-input
          v-if="branches.length"
          name="Sicil"
          v-model="branch"
          :options="branches"
          :reduce="(option) => option._id"
          label="Sicil:"
          placeholder="Sicil Seçiniz..."
          select_label="name"
          rules="required"
        />
      </div>
      <app-date-input
        v-model="startDate"
        name="Planlanan Ziyaret Tarihi"
        label="Planlanan Ziyaret Tarihi:"
        :min-date="new Date().setHours(0, 0, 0, 0)"
        placeholder="Tarih Seçiniz..."
        rules="required"
      />
      <hr />
      <app-button @click="create" text="Oluştur" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import assignedBranches from "../../ISG/assignedBranches.vue";
export default {
  components: { ValidationObserver, ValidationProvider, assignedBranches },
  data() {
    return {
      company: null,
      branch: null,
      startDate: null,
      branches: [],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    user() {
      return this.$store.getters?.user;
    },
    companies() {
      return this.$store.getters.assignedCompanies;
    },
    companySelect: {
      get() {
        return this.company;
      },
      set(item) {
        if (!item) this.branch = null;

        this.company = item ?? null;
        this.branches = item?.assignedBranches ?? [];
      },
    },
  },
  methods: {
    setData() {
      return {
        company: this.selectedCompany ?? this.company?.company?._id,
        branch: this.branch,
        physician: this.user?.physician?._id,
        physicianRole: this.user?.role?.key,
        startDate: new Date(this.startDate),
      };
    },
    create() {
      this.$validate(this.$refs.visitform, () => {
        this.$store.dispatch("newVisit", { data: this.setData(), actionType: "setNewPlanedVisit" });
      });
    },
  },
  mounted() {
    if (this.selectedCompany) {
      this.company = this.selectedCompany;
    } else {
      this.$store.dispatch("getAssignedCompanies", "selectBox");
    }
  },
};
</script>

<style></style>
