<template>
  <div>
    <b-collapse :visible="isCompanyCollapseVisible">
      <company-list @company-selected="selectCompany" />
    </b-collapse>
    <b-collapse :visible="isBranchCollapseVisible">
      <branch-list />
    </b-collapse>
    <div v-show="!isCompanyCollapseVisible && !isBranchCollapseVisible">
      <component :is="components[mainTab]" />
    </div>
  </div>
</template>

<script>
import companyList from "./companyList.vue";
import VisitTabs from "../Visits/VisitTabs.vue";
import CompanyInfoTabs from "./companyInfo/CompanyInfoTabs.vue";
import ISGTabs from "./ISGTabs.vue";
import branchList from "./branchList.vue";

export default {
  components: {
    companyList,
    branchList,
  },
  props: {
    tabIndex: {
      type: Number,
    },
    mainTab: {
      type: Number,
    },
  },
  data() {
    return {
      components: [ISGTabs, VisitTabs, CompanyInfoTabs],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    physician() {
      return this.$store.getters?.user?.physician?._id;
    },
    currentTab: {
      get() {
        return this.mainTab;
      },
      set() {},
    },
    isCompanyCollapseVisible() {
      return this.$store.getters.isCompanyCollapseVisible;
    },
    isBranchCollapseVisible() {
      return this.$store.getters.isBranchCollapseVisible;
    },
    windowWidth() {
      return this.$store.state.app.windowWidth;
    },
  },
  methods: {
    selectCompany() {
      this.$store.commit("setCompanyCollapseVisible", false);
    },
  },
  mounted() {
    this.$store.commit("setCompanyCollapseVisible", Boolean(!this.selectedCompany));
  },
};
</script>

<style>
.company-tab-container {
  position: relative;
}

.company-custom-tab-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  transition: linear 0.2s;
  padding: 10px 0;
}

.company-tab-arrow-left-custom {
  left: 1%;
}

.company-tab-arrow-right-custom {
  right: 1%;
}
</style>
