<template>
  <b-card body-class="p-1" class="mb-1 border">
    <app-detail-table :data="item" :fields="fields" />
    <hr />
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "auth.fullname", label: "İsim" },
        { key: "auth.email", label: "E-Posta" },
        { key: "auth.phone", label: "Telefon" },
        { key: "auth.role.value", label: "Rol" },
        { key: "duty", label: "Unvan" },
      ],
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appModalItem;
    },
  },
};
</script>

<style></style>
