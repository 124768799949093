<template>
  <GmapMap
    id="map"
    ref="mapRef"
    :options="{
      zoomControl: true,
      fullscreenControl: true,
      clickableIcons: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      disableDefaultUi: false,
    }"
    :center="center"
    :zoom="zoom"
    style="width: 100%; height: 65vh"
  >
    <GmapMarker
      v-for="(item, key) in branchLocations"
      :key="key"
      :position="item.address.location"
      :icon="icon"
      @click="(isWindowOpen = true), (windowItem = item), setLocation(item.address.location)"
    />
    <gmap-info-window
      v-if="windowItem"
      :position="windowItem.address.location"
      :opened="isWindowOpen"
      :options="{
        pixelOffset: {
          width: 0,
          height: -35,
        },
      }"
      @closeclick="closeClick"
    >
      <div class="d-flex flex-column align-items-center mt-1">
        <h5 class="m-0 text-center">
          {{ windowItem.name }}
          <hr />
          <app-button @click="goToLocation(windowItem)" text="Konuma Git" icon="MapPinIcon" block />
        </h5>
      </div>
    </gmap-info-window>
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  data() {
    return {
      center: { lat: 38.963745, lng: 35.243322 },
      zoom: 6,
      isWindowOpen: false,
      windowItem: null,
      icon: require("@/assets/images/building.png"),
    };
  },
  computed: {
    branchLocations() {
      return this.$store.getters?.selectedCompany?.assignedBranches.filter((item) => item?.address?.location?.lat && item?.address?.location?.lng);
    },
    google() {
      return new gmapApi();
    },
  },
  methods: {
    setLocation(loc, zoom = 16) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.setCenter(loc);
        map.setZoom(zoom);
      });
    },
    getBounds() {
      this.$nextTick(() => {
        if (this?.google?.maps?.LatLngBounds) {
          const bounds = new this.google.maps.LatLngBounds();
          for (const item of this.branchLocations) {
            bounds.extend(new google.maps.LatLng(item?.address?.location?.lat, item?.address?.location?.lng));
          }
          this.$refs.mapRef.$mapPromise.then(() => {
            this.$refs.mapRef.$mapObject.fitBounds(bounds);
          });
        }
      });
    },
    closeClick() {
      this.isWindowOpen = false;
      this.windowItem = null;
      this.branchLocations.length > 1 ? this.getBounds() : this.setLocation(this.branchLocations[0].address.location);
    },
    goToLocation(item) {
      if (item?.address?.location?.lat && item?.address?.location?.lng) {
        window.open(`http://maps.google.com?q=${item?.address?.location?.lat},${item?.address?.location?.lng}`, "_blank");
      }
    },
  },
  watch: {
    google(val) {
      if (val.maps) {
        this.branchLocations.length > 1 ? this.getBounds() : this.setLocation(this.branchLocations[0].address.location);
      }
    },
    branchLocations(val) {
      if (val.length > 0) {
        val.length === 1 ? this.setLocation(val[0].address.location) : this.getBounds();
      } else {
        this.setLocation(this.center, 6);
      }
    },
  },
  mounted() {
    this.google;
    this.$nextTick(() => {
      if (this.google.maps) {
        this.branchLocations.length > 1 ? this.getBounds() : this.setLocation(this.branchLocations[0].address.location);
      }
    });
  },
};
</script>
