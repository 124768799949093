<template>
  <db-searchable-table v-model="filters" @changed="getData" :items="visits" :fields="fields" :total-rows="total" hover pagination @row-clicked="showDetails" no-border no-body-gap>
    <template #company="data">
      <span v-b-tooltip.hover.top="data.item.company.name.length > 20 ? data.item.company.name : ''">{{ $fn(data.item.company.name, 20) }}</span>
    </template>
    <template #physician="data">
      <app-user-info v-if="data.item.physician" :title="data.item.physician.auth.fullname" :alt_title="$physicianRole(data.item.physicianRole)" icon="" variant="secondary" />
      <span v-else> - </span>
    </template>
    <template #status="{ item }">
      <b-badge :variant="$variants[item.status.key]">
        {{ item.status.value }}
      </b-badge>
    </template>
  </db-searchable-table>
</template>

<script>
export default {
  props: {
    query: { type: Object, default: () => ({}) },
    fields: { type: Array, default: () => [] },
    getterKey: { type: String, default: "visits" },
    actionType: { type: String, default: "setVisits" },
  },
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    visits() {
      return this.$store.getters[this.getterKey]?.data ?? [];
    },
    total() {
      return this.$store.getters[this.getterKey]?.total ?? 0;
    },
  },
  methods: {
    showDetails(item) {
      this.$router.push({ name: "physician-visit-detail", params: { id: item?._id } });
    },
    getData() {
      this.$store.dispatch("getVisits", { query: { ...this.query, ...this.filters }, actionType: this.actionType });
    },
  },
  watch: {
    query: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  destroyed() {
    this.$store.commit(this.actionType, { data: [], total: 0 });
  },
};
</script>

<style></style>
