var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"65vh"},attrs:{"id":"map","options":{
    zoomControl: true,
    fullscreenControl: true,
    clickableIcons: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    disableDefaultUi: false,
  },"center":_vm.center,"zoom":_vm.zoom}},[_vm._l((_vm.branchLocations),function(item,key){return _c('GmapMarker',{key:key,attrs:{"position":item.address.location,"icon":_vm.icon},on:{"click":function($event){(_vm.isWindowOpen = true), (_vm.windowItem = item), _vm.setLocation(item.address.location)}}})}),(_vm.windowItem)?_c('gmap-info-window',{attrs:{"position":_vm.windowItem.address.location,"opened":_vm.isWindowOpen,"options":{
      pixelOffset: {
        width: 0,
        height: -35,
      },
    }},on:{"closeclick":_vm.closeClick}},[_c('div',{staticClass:"d-flex flex-column align-items-center mt-1"},[_c('h5',{staticClass:"m-0 text-center"},[_vm._v(" "+_vm._s(_vm.windowItem.name)+" "),_c('hr'),_c('app-button',{attrs:{"text":"Konuma Git","icon":"MapPinIcon","block":""},on:{"click":function($event){return _vm.goToLocation(_vm.windowItem)}}})],1)])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }