<template>
  <app-overlay loading-variable="modalOverlay">
    <b-card body-class="p-1" class="border mb-1">
      <app-button class="flex-1-md mb-1" text="Not Ekle" icon="PlusIcon" size="md" @click="$showAppModalSidebar('Not Ekle', OfficialNoteForm)" />
      <b-alert variant="warning" :show="showAlert" fade>
        <div class="alert-body">
          <feather-icon class="mr-50" icon="InfoIcon" />
          <span>Henüz not eklenmemiştir. </span>
        </div>
      </b-alert>
      <b-card v-for="(item, key) in notes" :key="key" body-class="p-1" class="position-static border mb-75">
        <blockquote class="blockquote mb-0">
          <p v-if="item && item.note">
            {{ item.note }}
          </p>
          <footer class="blockquote-footer">
            <small class="text-muted">
              <cite v-if="item && item.createdAt" title="Source Title">{{ $FormattedDateTime(item.createdAt) }} tarihinde oluşturuldu.</cite>
            </small>
          </footer>
        </blockquote>
      </b-card>
    </b-card>
  </app-overlay>
</template>

<script>
import OfficialNoteForm from "./OfficialNoteForm.vue";

export default {
  data() {
    return {
      showAlert: false,
      OfficialNoteForm,
    };
  },
  computed: {
    official() {
      return this.$store.getters.appModalItem?._id;
    },
    notes() {
      return this.$store.getters.officialNotes;
    },
  },
  watch: {
    notes() {
      this.showAlert = Boolean(!this.notes.length);
    },
  },
  mounted() {
    this.getNotes();
  },
  methods: {
    getNotes() {
      if (this.official) {
        this.$axios
          .get("/notes", { params: { related: this.official }, loading: "modal" })
          .then((res) => {
            this.$store.commit("setOfficialNotes", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style></style>
