<template>
  <div v-if="selectedCompany">
    <b-card :body-class="{ 'p-1': windowWidth > 768, 'p-50': windowWidth < 767 }">
      <b-custom-tabs :tabs="tabs" lazy />
    </b-card>
  </div>
</template>

<script>
import CompanyInfoTable from "./CompanyInfoTable.vue";
import OfficialList from "./officials/OfficialList.vue";
import BranchLocations from "./BranchLocations.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Genel Bilgiler", component: CompanyInfoTable },
        { title: "Yetkililer", component: OfficialList },
        { title: "Konumlar", component: BranchLocations },
      ],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
};
</script>

<style></style>
