<template>
  <app-select-input
    v-model="docTemplate"
    :options="options"
    :reduce="(option) => option._id"
    select_label="name"
    name="Dosya"
    label="Dosya:"
    placeholder="Dosya Seçiniz..."
    rules="required"
    :loading="$store.getters.selectBoxOverlay"
    @input="$emit('input', docTemplate)"
  />
</template>

<script>
export default {
  props: {
    fileGroupValue: String,
  },
  data() {
    return {
      docTemplate: null,
      options: [],
    }
  },
  watch: {
    fileGroupValue() {
      this.getOptions()
    },
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    getOptions() {
      this.options = []
      this.docTemplate = null
      this.$emit('input', null)
      this.$axios
        .get('/doc-templates', { loading: 'selectBox', params: { 'fileGroup.value': this.fileGroupValue } })
        .then(result => {
          this.options = result.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style></style>
