<template>
  <visit-layout>
    <template #list>
      <visit-list :fields="fields" :query="query" getterKey="planedVisits" actionType="setPlanedVisits" />
    </template>
    <template #calendar>
      <visit-calendar :query="query" />
    </template>
  </visit-layout>
</template>

<script>
import VisitLayout from "./visitLayout/VisitLayout.vue";
import VisitList from "./visitLayout/VisitList.vue";
import VisitCalendar from "./visitLayout/VisitCalendar.vue";

export default {
  components: { VisitLayout, VisitList, VisitCalendar },
  computed: {
    query() {
      return {
        "status.key": "planed",
        ...(this.selectedCompany && this.assignedBranches.length
          ? { company: this.selectedCompany?._id, ...(this.selectedBranch ? { branch: this.selectedBranch } : { "branch[$in]": this.assignedBranches.map((item) => item._id) }) }
          : {
              physician: this.physicianId,
            }),
      };
    },
    fields() {
      return this.currentBreakPoint === "xs"
        ? [
            {
              key: "startDate",
              label: "Ziyaret Tarihi",
              formatDate: true,
            },
            ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil Adı" }]),
          ]
        : [
            { key: "company", label: "Firma" },
            ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil Adı" }]),
            {
              key: "startDate",
              label: "Ziyaret Tarihi",
              formatDate: true,
            },
            {
              key: "createdAt",
              label: "Oluşturma Tarihi",
              formatDateTime: true,
            },
          ];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    assignedBranches() {
      return this?.selectedCompany?.assignedBranches;
    },
    physicianId() {
      return this.$store.getters?.user?.physician?._id;
    },
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
};
</script>
