<template>
  <app-overlay>
    <searchable-table class="border" :items="companies" :fields="fields" hover @row-clicked="selectCompany">
      <template #modalbutton>
        <h3 class="m-0 mr-50">Firma Seçiniz</h3>
        <app-button v-if="selectedCompany" @click="$store.commit('setSelectedCompany', null), $emit('company-selected')" text="Seçimi Kaldır" icon="XIcon" />
      </template>
      <template #company="{ item }">
        <span v-b-tooltip.hover.top="item.company.name.length > 20 ? item.company.name : ''">
          {{ $fn(item.company.name, 20) }}
        </span>
      </template>
      <template slot="company.companyConfirm" slot-scope="data">
        <b-badge :variant="$variants[data.item.company.companyConfirm]">
          {{ $capitalize(data.item.company.companyConfirm) }}
        </b-badge>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
export default {
  computed: {
    companies() {
      return this.$store.getters.assignedCompanies;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    fields() {
      if (this.windowWidth < 768) {
        return [{ key: "company", label: "Firma", sortable: true }];
      }
      return [
        { key: "company", label: "Firma", sortable: true },
        { key: "company.branchCount", label: "Atanan Sicil", sortable: true },
        { key: "company.employeeCount", label: "Toplam Çalışan" },
        { key: "company.companyConfirm", label: "Firma Onayı" },
      ];
    },
  },
  mounted() {
    this.$store.dispatch("getAssignedCompanies", "table");
  },
  methods: {
    selectCompany(item) {
      this.$store.commit("setSelectedBranch", null);
      this.$store.commit("setSelectedCompany", {
        ...item.company,
        assignedBranches: item.assignedBranches,
      });
      this.$emit("company-selected", item?.company);
    },
  },
};
</script>

<style></style>
